const React = require('react');
const PropTypes = require('prop-types');

const Tooltip = require('@andes/tooltip');

const EditableButtons = require('../appearance/EditableButtons');
const Editable = require('../appearance/Editable').default;
const Message = require('../components/message');

const renderMe = () => true;

const createEditable = (Base, getProps, renderWrapper = renderMe, renderBase = renderMe) => {
  const Wrapper = (props) => {
    const { hidden, i18n, advice_message: message } = props;
    const wrapperRef = React.createRef();
    const editableProps = getProps(props);
    const editButton = {
      label: hidden ? i18n.gettext('Activar sección') : i18n.gettext('Editar'),
      ...editableProps,
    };

    const tooltip = {
      title: i18n.gettext('Sección inactiva'),
      content: i18n.gettext('Edita esta sección para activarla en tu tienda.'),
      className: 'tooltip--editable',
      side: 'top',
      autoHideDelay: 2000,
    };

    if (!renderWrapper(props)) {
      return renderBase(props) ? <Base {...props} /> : null;
    }

    if (message.type === 'warning') {
      return (
        <div className="editable-container editable-container--warning">
          {message.link
            ? (
              <div className="editable-container__base">
                <a
                  className="editable-container__base--link"
                  href={message.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Base {...props} />
                </a>
              </div>
            )
            : (
              <div className="editable-container__base">
                <Base {...props} />
              </div>
            )}
          <Message {...message} />
        </div>
      );
    }

    if (message.type === 'info') {
      return (
        <div className="editable-container editable-container--info" ref={wrapperRef}>
          <Editable
            className="editable-wrapper"
            type={editButton.type}
            payload={editButton.payload}
            wrapperRef={wrapperRef}
          >
            <div className="editable-container__base--link">
              <Base {...props} />
            </div>
            <EditableButtons editButton={editButton} showDelete={false} />
          </Editable>
          <Message {...message} />
        </div>
      );
    }

    return (
      <div className="editable-wrapper editable-wrapper--with-border wrapper--editable" ref={wrapperRef}>
        <Editable type={editButton.type} payload={editButton.payload} wrapperRef={wrapperRef}>
          { hidden
            ? (
              <Tooltip {...tooltip}>
                <Base {...props} />
              </Tooltip>
            )
            : <Base {...props} />}
          <EditableButtons editButton={editButton} showDelete={false} />
        </Editable>
      </div>
    );
  };

  Wrapper.propTypes = {
    hidden: PropTypes.bool,
    i18n: PropTypes.shape(),
    advice_message: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      type: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  };

  Wrapper.defaultProps = {
    hidden: false,
    advice_message: {
      text: '',
      icon: '',
      type: '',
      link: {
        text: '',
        url: '',
      },
    },
    i18n: { gettext: f => f },
  };

  return Wrapper;
};

module.exports = createEditable;
