const React = require('react');
const PropTypes = require('prop-types');

const SlideCTA = ({ className, cta }) => (
  <button type="button" className={className}>
    <span>{cta}</span>
  </button>
);

SlideCTA.propTypes = {
  className: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
};

module.exports = SlideCTA;
