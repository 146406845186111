module.exports = {
  AFIP: 'AFIP',
  BANNER: 'BANNER',
  CAROUSEL: 'CAROUSEL',
  CAROUSEL_SPLINTER: { brands: 'BRANDS', categories: 'CATEGORIES' },
  COLLECTION_GRID: 'GRID',
  CONTACT: 'CONTACT',
  DISCOVERY: 'DISCOVERY',
  FLASH: 'FLASH',
  FOOTER: 'FOOTER',
  HEADER: 'HEADER',
  HEADER_MESSAGE: 'HEADER_MESSAGE',
  LOGO: 'LOGO',
  MOSAIC: 'MOSAIC',
  PAYMENT_DATA: 'PAYMENT_DATA',
  SOCIAL_NETWORKS: 'SOCIAL_NETWORKS',
  SLIDER: 'SLIDER',
  SPLINTER_BANNER: 'TEXT_BANNER',
  PRICE_AND_IMAGE: 'PRICE_AND_IMAGE',
  TABBED_CAROUSEL: 'TABBED_CAROUSEL',
  WHATSAPP: 'WHATSAPP',
};
