const Base = require('.');
const createEditable = require('../../adapters/createEditable');

const types = require('../Editable/types');

const getMosaicProps = (props) => {
  const { componentId, title, maxItems } = props;
  return {
    type: types.MOSAIC,
    payload: { componentId, title, maxItems },
  };
};

module.exports = createEditable(Base, getMosaicProps);
