const React = require('react');
const PropTypes = require('prop-types');

const createEditable = require('../../adapters/createEditable');

const Discovery = require('./index');
const FakeContainer = require('./fakeContainer');

const { EDITABLE_FAKE_CHILDREN, getDiscoveryProps } = require('./utils');

const Wrapper = (props) => {
  const { hidden, children } = props;
  const fakeChildren = EDITABLE_FAKE_CHILDREN[children.length] || [];
  const isHidden = hidden || (children.length < 3);

  const drawFakeContainers = () => fakeChildren.map(fake => <FakeContainer {...fake} {...props} />);

  return <Discovery {...props} hidden={isHidden} fakeChildren={drawFakeContainers} editable />;
};

Wrapper.propTypes = {
  title: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

module.exports = createEditable(Wrapper, getDiscoveryProps);
