module.exports = {
  FAVICON: {
    href: 'https://http2.mlstatic.com/storage/mshops-appearance-api/resources/sections/home/favicon-v1.png',
    type: 'image/png',
  },
  COOKIES: {
    D2ID: '_d2id',
    CP: 'cp',
    SSID: 'ssid',
  },
  TRACK_COOKIES_TITLE: 'TrackCookies',
  THEMES: {
    MATERIAL: 'material',
  },
};
