const PropTypes = require('prop-types');
const React = require('react');
const classNames = require('classnames');
const Section = require('../section');
const Action = require('../action');

function RecommendationsContainer({
  children,
  display,
  highlight_seeds: highlightSeeds,
  title,
  type,
  view_more_action: viewMoreAction,
}) {
  const sectionHeaderClassName = classNames('section-header', `section-header__${display}`);
  const rowContainerClassName = classNames('row container', { 'highlight-seeds': highlightSeeds });
  return (
    <Section type={type}>
      <div className={rowContainerClassName}>
        <div className={sectionHeaderClassName}>
          <h1>{title}</h1>
          {viewMoreAction && <Action {...viewMoreAction} />}
        </div>
        {children}
      </div>
    </Section>
  );
}

RecommendationsContainer.propTypes = {
  display: PropTypes.string,
  children: PropTypes.node.isRequired,
  highlight_seeds: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  view_more_action: PropTypes.shape(Action.propTypes),
};

RecommendationsContainer.defaultProps = {
  display: 'original',
  highlight_seeds: false,
  view_more_action: null,
};

module.exports = RecommendationsContainer;
