/* global window */
const React = require('react');
const PropTypes = require('prop-types');

const types = require('./types');
const { ARROW_SIZE } = require('../../utils/constants/slider');
const { DEVICE_TYPE_MOBILE } = require('../../utils/constants/tabbedCarousel');

const withEditable = require('../../adapters/withEditable');

class Editable extends React.Component {
  constructor(props) {
    super(props);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.warmup = this.warmup.bind(this);
    this.getWrapperCoords = this.getWrapperCoords.bind(this);
    this.getSliderPosition = this.getSliderPosition.bind(this);
    this.isSliderWithArrows = this.isSliderWithArrows.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.warmup);
    this.getSliderPosition();
  }

  componentDidUpdate() {
    this.getSliderPosition();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.warmup);
  }

  onMouseDown(event) {
    event.preventDefault();
    const { type, payload, targets, sendMessage } = this.props;
    const isSliderWithArrows = this.isSliderWithArrows(event);
    // We do this to prevent the sidebar from opening when clicking on the arrows on the slider
    if (isSliderWithArrows) return;

    if (targets) {
      this.sendMessageByTarget(event, targets);
    } else {
      const coords = this.getWrapperCoords();
      sendMessage(type, payload, coords);
    }
  }

  getSliderPosition() {
    this.slider = document.querySelector('.slider');
    this.sliderPosition = this.slider && this.slider.getBoundingClientRect();
  }

  getWrapperCoords() {
    const { wrapperRef } = this.props;
    const coords = wrapperRef && wrapperRef.current.getBoundingClientRect();
    // Firefox does not allow to send in postMessage a DOMRect object
    // so we do a destructure here to change its type from DOMRect to a JSON
    return coords ? { top: coords.top, left: coords.left, width: coords.width, height: coords.height } : {};
  }

  isSliderWithArrows(event) {
    const { clientX } = event;
    const { type, payload, deviceType } = this.props;

    const isTypeSlider = (type === types.SLIDER);
    const hasMoreThanOneSlide = payload.slides && (payload.slides.length > 1);
    const isNotMobile = (deviceType !== DEVICE_TYPE_MOBILE);

    return (
      isTypeSlider
      && hasMoreThanOneSlide
      && isNotMobile
      && this.sliderPosition
      && (clientX < this.sliderPosition.left + ARROW_SIZE || clientX > this.sliderPosition.right - ARROW_SIZE)
    );
  }

  sendMessageByTarget(event, targets) {
    const { sendMessage } = this.props;
    const classList = (event.target.className || '').split(' ');

    classList.forEach((className) => {
      const target = targets[className];
      if (target) {
        sendMessage(target.type, target.payload);
      }
    });
  }

  warmup({ data }) {
    if (data === 'warmup') {
      const { type, payload, sendMessage } = this.props;

      const coords = this.getWrapperCoords();
      // we fake a warmup event to update element coords on admin
      sendMessage(type, payload, coords, { warmup: true });
    }
  }

  render() {
    const { className, style, children, wrapperRef } = this.props;

    return (
      <div
        ref={wrapperRef}
        className={className}
        style={style}
        role="button"
        tabIndex="-1"
        onMouseDown={this.onMouseDown}
        onClickCapture={this.onClickCapture}
      >
        {children}
      </div>
    );
  }
}

Editable.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  sendMessage: PropTypes.func.isRequired,
  payload: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  type: PropTypes.string,
  targets: PropTypes.objectOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    payload: PropTypes.any,
  })),
  style: PropTypes.shape({}),
  wrapperRef: PropTypes.shape(),
  deviceType: PropTypes.string.isRequired,
};

Editable.defaultProps = {
  className: '',
  children: null,
  payload: {},
  targets: null,
  type: null,
  style: {},
  wrapperRef: null,
};

module.exports = {
  default: withEditable(Editable),
  Editable,
};
