const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const withHover = require('../../adapters/withHover');
const withEditable = require('../../adapters/withEditable');

const Types = require('../Editable/types');

const EditableButtons = require('../EditableButtons');
const Editable = require('../Editable').default;

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const DEFAULT_DISPLAY = 'original';

function createEditable(Base) {
  const WrappedComponent = (props, { metadata: { layoutTheme, deviceType } }) => {
    const {
      display,
      componentId,
      slides,
      size,
      i18n,
      hidden,
      banner_properties: bannerProperties,
      hovered,
    } = props;
    const { id, image_url: imageUrl, alignment } = bannerProperties || {};
    const sliderRef = React.createRef();
    const emptyConfig = hidden || !slides || (slides.length === 0);
    const sliderWrapperClassNames = classNames('editable-wrapper slider--editable', {
      'slider--editable-hovered': hovered,
      'slider--editable-fake': emptyConfig,
    });
    const slidesPayload = { componentId, slides, size, display: display || DEFAULT_DISPLAY };
    let editableProps;
    if (layoutTheme === 'simple') {
      editableProps = {
        label: i18n.gettext('Editar'),
        type: Types.SLIDER,
        options: [{
          label: i18n.gettext('Carrusel de diapositivas'),
          type: Types.SLIDER,
          payload: slidesPayload,
          wrapperRef: sliderRef,
          selected: true,
        }, {
          label: i18n.gettext('Imagen decorativa'),
          type: Types.BANNER,
          payload: { componentId: 'banner', id, imageUrl, alignment } },
        ],
      };
    } else {
      editableProps = {
        label: i18n.gettext('Editar'),
        type: Types.SLIDER,
        payload: slidesPayload,
        wrapperRef: sliderRef,
      };
    }

    return (
      <div className={sliderWrapperClassNames} ref={sliderRef}>
        <Editable
          deviceType={deviceType}
          type={editableProps.type}
          payload={slidesPayload}
          className="editable_fullheight"
          wrapperRef={sliderRef}
        >
          { emptyConfig && <div className="slider--editable__fake-slide" /> }
          <Base {...props} />
          <EditableButtons
            editButton={editableProps}
            showDelete={false}
          />
        </Editable>
      </div>
    );
  };

  WrappedComponent.propTypes = {
    componentId: PropTypes.string.isRequired,
    size: PropTypes.string,
    hovered: PropTypes.bool.isRequired,
    i18n: PropTypes.string,
    slides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      action: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        url_alternative: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        alignment: PropTypes.oneOf(['top', 'center', 'bottom']),
      }),
      alt: PropTypes.string,
      text_alignment: PropTypes.oneOf(['left', 'center', 'right']),
    })),
    banner_properties: PropTypes.shape({
      id: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
      alignment: PropTypes.oneOf(['top', 'center', 'bottom']).isRequired,
    }).isRequired,
    hidden: PropTypes.bool,
    display: PropTypes.oneOf(['original', 'material']).isRequired,
  };

  WrappedComponent.defaultProps = {
    i18n: { gettext: f => f },
    slides: [{}],
    size: 'small',
    hidden: false,
  };

  WrappedComponent.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return WrappedComponent;
}

module.exports = Base => withHover(withEditable(createEditable(Base)));
