const MSAppearance = require('mshops-web-utils/tools/appearance');

class AppearanceService {
  constructor(appearance) {
    this.appearance = new MSAppearance(appearance);
  }

  replaceLayoutChildren(newChildren) {
    const layoutPointer = this.appearance.getComponentByName('Layout');

    if (layoutPointer === null) {
      const Document = this.appearance.getComponentByName('Document');
      Document.children.append({
        component: 'Layout',
        properties: {},
        children: newChildren,
      });
    } else {
      layoutPointer.children = newChildren;
    }
  }

  hasComponent(componentName) {
    const check = name => !!this.appearance.getComponentByName(name);
    return check(`${componentName}Editable`) || check(componentName);
  }

  getComponentProperty(component, property) {
    return this.appearance.getComponentProperty(component, property);
  }
}

module.exports = AppearanceService;
