const defaultCarouselSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
  infinite: true,
  lazyLoad: true,
  slideSize: {
    width: 1600,
    height: 340,
  },
};

const SLIDER_SIZE = {
  desktop: {
    small: 256,
    medium: 384,
    large: 520,
  },
  tablet: {
    small: 256,
    medium: 384,
    large: 520,
  },
  mobile: {
    small: 256,
    medium: 384,
    large: 520,
  },
};

const CLASS_BY_VARIATION = {
  original: 'slider',
  material: 'slider-material',
};

module.exports = {
  defaultCarouselSettings,
  SLIDER_SIZE,
  CLASS_BY_VARIATION,
};
