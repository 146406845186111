const React = require('react');
const PropTypes = require('prop-types');

const SecurityIcon = require('./icons/Security');

const Title = ({ title, subtitle, text }) => (
  <section className="lgpd-landing__title-section">
    <h1 className="title-section__title">{title}</h1>
    <h2 className="title-section__subtitle">{subtitle}</h2>
    <p className="title-section__text">{text}</p>
    <SecurityIcon className="title-section__icon" />
  </section>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

module.exports = Title;
