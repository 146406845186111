const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const FakeCard = require('../../components/placeholder/FakeCard');

const WrapperEditable = (Base) => {
  const Wrapper = (props) => {
    const {
      items,
      title,
      view_more_action: viewMoreAction,
      empty_state_card: emptyStateCard,
      display,
      itemsPerRow,
      maxItems,
    } = props;
    const itemAmount = items.length;
    const fillNeeded = itemAmount < maxItems;
    const isEmpty = itemAmount === 0;
    const emptyState = isEmpty || fillNeeded;
    const fakeLength = fillNeeded ? maxItems - itemAmount : itemsPerRow;
    const showHeader = title && viewMoreAction;
    const fakeItems = new Array(fakeLength).fill({});

    const fakeCardContainerGrid = classNames('fake-card-container__cards', {
      'fake-card-container__cards-grid': maxItems > 5,
      'grid-6': maxItems === 6,
      'grid-8': maxItems === 8,
      'grid-10': maxItems === 10,
    });

    return emptyState ? (
      <div className="fake-card-container fake-card-container--grid">
        {showHeader && (
          <div className="fake-card-container__header">
            <h3 className="fake-card-container__header-title">{title}</h3>

            {viewMoreAction.label && (
              <div className="fake-card-container__header-action">
                {viewMoreAction.label}
              </div>
            )}
          </div>
        )}

        <div className={fakeCardContainerGrid}>
          {fillNeeded
            && items.map((item) => (
              <FakeCard
                cardSize={itemsPerRow}
                currencySymbol={item.price.currency_symbol}
                price={item.price.value}
                fees={item.installments}
                title={item.title}
                icon={item.picture.src}
                discountLabel={item.discount_label}
                display={display}
              />
            ))}
          {fakeItems.map(() => (
            <FakeCard cardSize={itemsPerRow} {...emptyStateCard} display={display} />
          ))}
        </div>
      </div>
    ) : (
      <Base {...props} />
    );
  };

  Wrapper.propTypes = {
    hidden: PropTypes.bool.isRequired,
    display: PropTypes.string.isRequired,
    itemsPerRow: PropTypes.number.isRequired,
    maxItems: PropTypes.number.isRequired,
    items: PropTypes.shape({
      installments: PropTypes.string,
      title: PropTypes.string,
      picture: PropTypes.shape({
        src: PropTypes.string,
      }),
      price: PropTypes.shape({
        currencySymbol: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
    title: PropTypes.string,
    view_more_action: PropTypes.shape({
      label: PropTypes.string,
    }),
    empty_state_card: PropTypes.shape({
      icon: PropTypes.string,
      price: PropTypes.string,
      fees: PropTypes.string,
      title: PropTypes.string,
    }),
  };

  Wrapper.defaultProps = {
    title: '',
    items: {
      installments: '',
      title: '',
      picture: {
        src: '',
      },
      price: {
        currencySymbol: '',
        value: '',
      },
    },
    view_more_action: {
      label: '',
    },
    empty_state_card: PropTypes.shape({
      icon: '',
      price: '',
      fees: '',
      title: '',
    }),
  };

  Wrapper.contextTypes = {
    metadata: metadataPropType,
  };

  return Wrapper;
};

module.exports = WrapperEditable;
