const React = require('react');

const metadataPropType = require('../components/metadataProvider/metadataPropType');

function withHover(WrappedComponent) {
  class Hovered extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        hovered: false,
      };
      this.setHoveredStatusOn = this.setHoveredStatusOn.bind(this);
      this.setHoveredStatusOff = this.setHoveredStatusOff.bind(this);
    }

    setHoveredStatusOn() {
      this.setState({ hovered: true });
    }

    setHoveredStatusOff() {
      this.setState({ hovered: false });
    }

    render() {
      return (
        <div onMouseEnter={this.setHoveredStatusOn} onMouseLeave={this.setHoveredStatusOff}>
          <WrappedComponent
            {...this.props}
            hovered={this.state.hovered}
          />
        </div>
      );
    }
  }

  Hovered.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return Hovered;
}

module.exports = withHover;
