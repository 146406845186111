const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Image = require('../../image');
const withTracker = require('../../with-tracker');
const withTrackerOnPrint = require('../../with-tracker-on-print');

const FakeCardMaterial = ({ cardSize, price, fees, title, icon, currencySymbol, discountLabel }) => {
  const discountBoxClasses = classNames('fake-card__material-discount', 'nav-header');
  const discountBoxTextClasses = classNames('fake-card__material-discount-text', 'nav-header__logo');

  const fakeCardSize = classNames('fake-card__material', {
    'fake-card__material-big-element': cardSize === 2,
    'fake-card__material-medium-element': cardSize === 3,
    'fake-card__material-small-element': cardSize === 4 || cardSize === 0,
    'fake-card__material-smaller-element': cardSize === 5,
  });

  return (
    <div className={fakeCardSize}>
      {discountLabel
        && (
          <div className={discountBoxClasses}>
            <p className={discountBoxTextClasses}>{discountLabel}</p>
          </div>
        )
      }
      <div className="fake-card__material-image">
        <Image src={icon} alt={title} />
      </div>
      <div className="fake-card__material-content">
        <p className="fake-card__material-content-title">{title}</p>
        <p className="fake-card__material-content-price">{currencySymbol} {price}</p>
        <p className="fake-card__material-content-fees">{fees}</p>
      </div>
    </div>
  );
};

FakeCardMaterial.propTypes = {
  currencySymbol: PropTypes.string,
  discountLabel: PropTypes.string,
  fees: PropTypes.string,
  icon: PropTypes.string,
  price: PropTypes.string,
  cardSize: PropTypes.number,
  title: PropTypes.string,
};

FakeCardMaterial.defaultProps = {
  currencySymbol: '',
  discountLabel: '',
  fees: '',
  icon: '',
  cardSize: 0,
  price: '',
  title: '',
};

module.exports = withTracker(withTrackerOnPrint(FakeCardMaterial));
