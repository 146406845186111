/**
 * Module dependencies
 */
const React = require('react');
const Props = require('./props');

const ItemOriginal = require('./item/desktop/itemOriginal');
const ItemMaterial = require('./item/desktop/itemMaterial');

const VARIATION_ELEMENT = {
  original: ItemOriginal,
  material: ItemMaterial,
};

const VariationItem = (props) => {
  const Item = VARIATION_ELEMENT[props.display];
  return <Item {...props} />;
};

VariationItem.propTypes = Props.propTypes;
VariationItem.defaultProps = Props.defaultProps;

module.exports = VariationItem;
