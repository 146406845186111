const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');

const EditableButtons = require('../EditableButtons');
const Editable = require('../Editable').default;

const types = require('../Editable/types');

class Wrapper extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      style: {
        height: 0,
      },
    };

    this.updateSize = this.updateSize.bind(this);
  }

  componentDidMount() {
    if (!window) {
      return;
    }

    this.updateSize();
  }

  updateSize() {
    // we calculate footer height taking body height and subtracting main element height
    const body = window.document.querySelector('body');
    const main = window.document.querySelector('main');
    const banner = window.document.querySelector('.nav-header__banner');

    const bodyHeight = body ? body.offsetHeight : 0;
    const mainHeight = main ? main.offsetHeight : 0;

    const height = bodyHeight - mainHeight;
    // if there is a banner, we also need to subtract its height cause it is included in body, but not in main
    this.setState({ style: { height: banner ? height - banner.offsetHeight : height } });
  }

  render() {
    const { fiscalData, socialNetworks, contact } = this.context.metadata;
    const {
      componentId,
      i18n,
      logo_caption: logoCaption,
      show_business_data: showBusinessData,
      legal_name: businessDataName,
      legal_doc: businessDataDoc,
    } = this.props;

    const editButton = {
      label: i18n.gettext('Editar'),
      type: types.FOOTER,
      payload: {
        componentId,
        contact,
        socialNetworks,
        qrCode: fiscalData ? fiscalData.qr_id : null,
        logoCaption,
        showBusinessData,
        businessDataName,
        businessDataDoc,
      },
    };

    const editableClasses = classNames(
      'footer--editable',
      'editable-wrapper',
    );

    return (
      <Editable
        className={editableClasses}
        payload={editButton.payload}
        style={this.state.style}
        type={editButton.type}
      >
        <EditableButtons editButton={editButton} showDelete={false} />
      </Editable>
    );
  }
}

Wrapper.propTypes = {
  componentId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  logo_caption: PropTypes.string,
  show_business_data: PropTypes.bool,
  legal_name: PropTypes.string,
  legal_doc: PropTypes.string,
};

Wrapper.defaultProps = {
  i18n: { gettext: f => f },
  logo_caption: '',
  show_business_data: false,
  legal_name: '',
  legal_doc: '',
};

Wrapper.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = Wrapper;
