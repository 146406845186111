const React = require('react');
const PropTypes = require('prop-types');
const metadataPropType = require('./metadataPropType');

class MetadataProvier extends React.Component {
  getChildContext() {
    return {
      metadata: this.props.metadata,
    };
  }

  render() {
    return this.props.children;
  }
}

MetadataProvier.childContextTypes = {
  metadata: metadataPropType.isRequired,
};

MetadataProvier.propTypes = {
  children: PropTypes.element.isRequired,
  metadata: metadataPropType.isRequired,
};

module.exports = MetadataProvier;
