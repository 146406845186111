const React = require('react');
const PropTypes = require('prop-types');

const metadataPropType = require('../../../../components/metadataProvider/metadataPropType');
const wordings = require('./wordings');

const Title = ({ i18n }, context) => {
  const { metadata } = context;
  const { shopModel } = metadata;
  const locales = wordings(i18n);

  return (
    <div className="how-buy-landing__title-section">
      <h1 className="title-section__title">{locales.title}</h1>
      <p className="title-section__text">
        {locales.text.first}
        <strong>{shopModel.shop.name}</strong>
        {locales.text.second}
      </p>
    </div>
  );
};

Title.contextTypes = {
  i18n: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.shape({
      first: PropTypes.string,
      second: PropTypes.string,
    }).isRequired,
  }),
  metadata: metadataPropType.isRequired,
};

module.exports = Title;
