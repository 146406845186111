const { componentList } = require('@splinter/all');

const splinterMapper = require('../../adapters/splinterMapper');
const createBannerEditable = require('./banner.editable');
const createCarouselEditable = require('./carousel.editable');
const createGridEditable = require('./grid.editable');

module.exports = {
  nonEditables: {
    BannerSplinter: splinterMapper(componentList.Banner),
    Card: splinterMapper(componentList.Card),
    Carousel: splinterMapper(componentList.Carousel),
    Grid: splinterMapper(componentList.Grid),
    Subtitle: splinterMapper(componentList.Subtitle),
  },
  editables: {
    BannerSplinterEditable: createBannerEditable(splinterMapper(componentList.Banner)),
    CardEditable: splinterMapper(componentList.Card),
    CarouselEditable: createCarouselEditable(splinterMapper(componentList.Carousel)),
    GridEditable: createGridEditable(splinterMapper(componentList.Grid)),
  },
};
