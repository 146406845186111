const React = require('react');
const PropTypes = require('prop-types');

const Item = require('../../components/item');
const metadataPropType = require('../../components/metadataProvider/metadataPropType');

function CarouselWrapper(RecommendationsBase) {
  function CarouselBase(props, context) {
    const { metadata } = context;
    return Boolean(props.items.length) && (
      <div className="mshops-recommendations-wrapper">
        <RecommendationsBase
          metadata={metadata.currency}
          restClient={metadata.restClient}
          type="recommendations"
          {...props}
        />
      </div>
    );
  }

  CarouselBase.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape(Item.itemPropTypes)).isRequired,
    title: PropTypes.string,
  };

  CarouselBase.defaultProps = {
    title: '',
  };

  CarouselBase.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return CarouselBase;
}

module.exports = CarouselWrapper;
