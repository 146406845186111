const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const KEYS = require('../../utils/constants/keys');

const TabList = ({ tabs, carouselActive, toggleCarousel }) => {
  const keyHandler = (event) => {
    if ((event.key === KEYS.ENTER.KEY) || (event.keyCode === KEYS.ENTER.KEY_CODE)) {
      toggleCarousel(event);
    }
  };
  return (
    <ul className="carousel-with-tabs__tabs-list">
      {
        tabs.map(tab => (tab.items.length
          ? (
            <li
              className={classNames(
                'tabs-list__item',
                { 'tabs-list__item--active': (carouselActive === tab.id) },
              )}
            >
              <div id={tab.id} onClick={toggleCarousel} onKeyDown={keyHandler} role="button" tabIndex="-1">
                {tab.label}
              </div>
            </li>
          )
          : null
        ))
      }
    </ul>
  );
};

TabList.propTypes = {
  toggleCarousel: PropTypes.func.isRequired,
  carouselActive: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

module.exports = TabList;
