/**
 * Grid is used as a container for SplinterBanner and other splinter components
 * Grid uses his own logic to draw his children so in order to make him capable of draw editable components
 * we need to add them in splinter library.
 * By now, we detect if BannerSplinter is his children to draw the editable wrapper
 */
const createEditable = require('../../adapters/createEditable');

const { isGridBannerFather, getGridBannerEditableProps } = require('./utils');

const renderWrapper = props => isGridBannerFather(props);

module.exports = Base => createEditable(Base, getGridBannerEditableProps, renderWrapper);
