const { REGEX } = require('./utils/constants/formatPrice');

class FormatPriceService {
  static getDiscount(item, decimalPlaces, separator) {
    if (!item.discount_label || !item.price.original_price) {
      return null;
    }

    const {
      currency_id: currencyId,
      currency_symbol: currencySymbol,
      original_price: originalPrice,
    } = item.price;

    return {
      text: `${item.discount_label} OFF`,
      original_price: {
        currency_id: currencyId,
        symbol: currencySymbol,
        fraction: this.getThousandSeparated({ price: originalPrice, separator }),
        cents: this.getDecimalPart(originalPrice, decimalPlaces),
      },
    };
  }

  static getDecimalPart(price, decimalPlaces) {
    const decimalPart = Math.floor((price - Math.floor(price)) * (10 ** decimalPlaces));
    let decimalString = '';
    if (decimalPart) {
      decimalString = decimalPart.toString();
    }
    return decimalString;
  }

  static getFormattedFraction(price) {
    return Math.floor(price).toString();
  }

  static getShipping({ free_shipping: freeShipping, label: text }) {
    if (!freeShipping || !text) {
      return null;
    }

    return { freeShipping, text };
  }

  static getThousandSeparated({ price, separator }) {
    // the ideal here would be using a locale string mapped to a siteId
    // eg: MLA - es-AR
    // but .toLocaleString has different behavior on node and browsers

    const fraction = this.getFormattedFraction(price);

    return fraction.replace(REGEX, separator);
  }
}

module.exports = FormatPriceService;
