const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const Button = require('@andes/button');
const withHover = require('../../adapters/withHover');
const withEditable = require('../../adapters/withEditable');
const types = require('../Editable/types');
const Message = require('../../components/message');

const metadataPropType = require('../../components/metadataProvider/metadataPropType');
const Edit = require('../EditableButtons/icons/Edit');
const { EyeOff } = require('../../components/icon');

const { ButtonText } = Button;

const { useState } = React;

const createEditable = (Base) => {
  const Editable = (props, context) => {
    const { layoutTheme } = context.metadata;
    const { hidden, i18n, hovered, advice_message: message, sendMessage, id } = props;
    const [hiddenState, setHidden] = useState(hidden);
    const isThemeSimple = (layoutTheme === 'simple');

    const actionText = hiddenState
      ? i18n.gettext('Activar seccion')
      : i18n.gettext('Desactivar seccion');

    const mainClass = classNames('payment-data-editable', {
      'payment-data-editable--hidden': hiddenState,
      'editable-hovered': hovered,
      'payment-data-editable--simple': isThemeSimple,
    });

    const buttonClasses = classNames(
      'editable-button',
      'editable-button--edit',
      'scale-up',
    );

    const buttonContainerClass = classNames(
      'payment-data-editable__button',
      'editable-wrapper',
    );
    const IcontoShow = hiddenState
      ? <Edit />
      : <EyeOff width={40} height={25} viewBox="0 0 32 33" />;

    const setHiddenStatus = () => {
      setHidden(!hiddenState);
      sendMessage(types.PAYMENT_DATA, {
        id,
        component: 'PaymentMethods',
        enabled: hiddenState,
      });
    };

    return (
      <div
        className={mainClass}
        onClick={setHiddenStatus}
        role="button"
        tabIndex="-1"
      >
        <Base {...props} />
        <div className={buttonContainerClass}>
          <Button
            onClick={setHiddenStatus}
            hierarchy="loud"
            className={buttonClasses}
          >
            {IcontoShow}
            <ButtonText>{actionText}</ButtonText>
          </Button>
        </div>
        {!isThemeSimple && message && (
          <div className="payments-message">
            <Message
              text={message.text}
              icon={message.icon}
              type={message.type}
              link={message.link}
            />
          </div>
        )}
      </div>
    );
  };

  Editable.propTypes = {
    hovered: PropTypes.bool.isRequired,
    enabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    i18n: PropTypes.shape({ gettext: PropTypes.func }),
    sendMessage: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired,
    advice_message: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  };

  Editable.defaultProps = {
    enabled: false,
    i18n: { gettext: f => f },
    advice_message: null,
  };

  Editable.contextTypes = {
    metadata: metadataPropType.isRequired,
  };

  return Editable;
};

module.exports = Base => withHover(withEditable(createEditable(Base)));
