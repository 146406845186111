const PropTypes = require('prop-types');
const React = require('react');

const { isInternal } = require('../../utils/linksHelpers');
const metadataPropType = require('../metadataProvider/metadataPropType');

function Action({ label, children, target, ...props }, context) {
  const { domain = '' } = context.metadata.shopModel.shop;
  const internalLink = isInternal(target, domain);
  const targetLink = internalLink ? '_self' : '_blank';
  const relValue = internalLink ? '' : 'noopener noreferrer';
  return (
    <a href={target} target={targetLink} rel={relValue} {...props}>
      {label || children}
    </a>
  );
}

/**
 * Prop types and defaults
 */
Action.propTypes = {
  target: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

Action.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = Action;
