/* global window document */
const React = require('react');
const PropTypes = require('prop-types');

const Document = require('./index');

const previewActions = require('./actions');

/**
 * Block the interaction with all the keys, except when
 * there's a combination with some keyCode in the `enabledKeys`.
 */
const current = {};
const blockAllKeys = (event) => {
  const enabledKeys = [
    16, // shift
    17, // ctrl
    91, // Windows Key / Left ⌘ / Chromebook Search key
    93, // Windows Menu / Right ⌘
  ];

  current[event.keyCode] = event.type === 'keydown';

  if (!enabledKeys.some(enabled => current[enabled])) {
    event.preventDefault();
  }
};

const handleDocumentMessage = ({ data }) => {
  const { message, action } = data;
  if ((message === 'preview-action') && previewActions[action]) {
    previewActions[action]();
  }
};

class DocumentEditable extends React.Component {
  componentDidMount() {
    if (typeof document === 'object') {
      document.addEventListener('keydown', blockAllKeys, false);
      document.addEventListener('keyup', blockAllKeys, false);
      window.addEventListener('message', handleDocumentMessage, false);
    }
  }

  componentWillUnmount() {
    if (typeof document === 'object') {
      document.removeEventListener('keydown', blockAllKeys, false);
      document.removeEventListener('keyup', blockAllKeys, false);
      window.removeEventListener('message', handleDocumentMessage, false);
    }
  }

  render() {
    return (
      <Document {...this.props} />
    );
  }
}

DocumentEditable.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

module.exports = DocumentEditable;
