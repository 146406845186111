const React = require('react');
const PropTypes = require('prop-types');
const Image = require('nordic/image');
const classNames = require('classnames');

const OriginalContent = ({ styles, content: { label, theme } }) => {
  const labelClass = classNames(
    'label-image__text',
    `label-image__text--${theme}`,
    { 'header--custom-background': (theme === 'custom') },
    { 'header--custom-text': (theme === 'custom') },
  );

  return (
    <div className="label-image__background">
      <Image
        className="label-image__position"
        src={styles.background_image}
        onload="on"
      />
      <div className={labelClass}><p>{label}</p></div>
    </div>
  );
};

OriginalContent.propTypes = {
  styles: PropTypes.shape({
    background_image: PropTypes.string,
  }).isRequired,
  content: PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
      onlyLink: PropTypes.bool,
    }).isRequired,
    label: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light', 'custom']),
    behavior: PropTypes.oneOf(['show', 'hide', 'hover']),
  }).isRequired,
};

module.exports = OriginalContent;
