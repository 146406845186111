const React = require('react');
const PropTypes = require('prop-types');
const uuid = require('uuid/v4');
const classnames = require('classnames');
const Card = require('../card-with-image-price');
const metadataPropType = require('../metadataProvider/metadataPropType');
const Section = require('../section');
const chunk = require('../../utils/chunk');

const { MOBILE, LENGTH_BREAKPOINT, GRID_SIZE_L, GRID_SIZE_SM, THEMES } = require('../../utils/constants/priceAndImage');

const PriceAndImage = ({ categories, title, theme, card_theme: cardTheme }, { metadata }) => {
  const isCustomTheme = theme === THEMES.CUSTOM;
  const elementsPerRow = (metadata.deviceType === MOBILE || categories.length <= LENGTH_BREAKPOINT)
    ? GRID_SIZE_SM
    : GRID_SIZE_L;
  const sectionClassName = classnames('price-and-image', {
    'header--custom-background': (isCustomTheme),
    [theme]: (!isCustomTheme),
  });
  const headingClassName = classnames('price-and-image__title', {
    'header--custom-text': isCustomTheme,
  });

  return (
    <Section className={sectionClassName}>
      <div className="row container price-and-image__container">
        <h1 className={headingClassName}>{title}</h1>
        { chunk(categories, elementsPerRow).map(row => (
          <section key={uuid()} className="price-and-image__row">
            { row.map(cardProps => (
              <Card key={uuid()} theme={cardTheme} {...cardProps} />
            )) }
          </section>
        )) }
      </div>
    </Section>
  );
};

PriceAndImage.propTypes = PropTypes.shape({
  card_theme: PropTypes.oneOf(['dark', 'light']).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.shape({
        currency_id: PropTypes.string.isRequired,
        currency_symbol: PropTypes.string.isRequired,
        original_price: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  theme: PropTypes.oneOf(['dark', 'light', 'custom']).isRequired,
  title: PropTypes.string.isRequired,
}).isRequired;

PriceAndImage.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = PriceAndImage;
