const React = require('react');

const Title = require('./components/Title');
const Steps = require('./components/Steps');
const Faq = require('./components/Faq');
const MoreInfoMessage = require('./components/MoreInfoMessage');

const HowBuyInfo = ({ i18n }) => (
  <div className="how-buy-landing">
    <Title i18n={i18n} />
    <Steps i18n={i18n} />
    <Faq i18n={i18n} />
    <MoreInfoMessage i18n={i18n} />
  </div>
);

module.exports = HowBuyInfo;
