/* eslint-env browser */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const SlideCTA = require('./SlideCTA');

const metadataPropType = require('../metadataProvider/metadataPropType');
const { CLASS_BY_VARIATION } = require('./config');
const classesHelper = require('../../utils/customBEMHelper');

const { useState, useEffect, useRef } = React;

const DESKTOP_DEFAULT_WIDTH = 1920;
const MOBILE_DEFAULT_WIDTH = 360;
const TOLERANCE = 1000;

const SlideImage = (
  { variation, image, sliderSize, text_alignment: textAlignment, heading, title, subtitle, cta, title_text_color: titleTextColor },
  { metadata: { deviceType } },
) => {
  const [adjustHeight, setAdjustHeight] = useState(false);
  const mounted = useRef(false);
  const listener = useRef(false);

  const classes = classesHelper({ name: CLASS_BY_VARIATION[variation] });

  const shouldAdjustHeight = () => {
    const isDesktop = (deviceType === 'desktop');
    const defaultWidth = isDesktop ? DESKTOP_DEFAULT_WIDTH : MOBILE_DEFAULT_WIDTH;
    const baseWidth = (mounted.current && typeof window === 'object') ? window.innerWidth : defaultWidth;

    const imageRatio = Math.round((image.height / image.width) * TOLERANCE);
    const sliderRatio = Math.round((sliderSize / baseWidth) * TOLERANCE);

    return isDesktop ? (imageRatio < sliderRatio) : (imageRatio >= sliderRatio);
  };

  const onWindowResize = () => {
    setAdjustHeight(shouldAdjustHeight());
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      onWindowResize();
    }

    if (!listener.current) {
      window.addEventListener('resize', onWindowResize);
      listener.current = true;
    }
  }, []);

  const imageAdjustedClass = classNames(
    classes('image'),
    adjustHeight ? 'image-adjust-height' : 'image-adjust-width',
  );

  const { url_alternative: urlAlternative, url, alignment } = image;
  const imageMobile = urlAlternative || url;
  const imageStyles = {
    backgroundImage: `url("${(deviceType === 'mobile') ? imageMobile : url}")`,
    backgroundPosition: alignment,
  };
  const textStyles = {
    color: `${titleTextColor}`,
  };

  const textWrapperClasses = classes('text-wrapper', {
    decorated: Boolean(heading.trim() || title.trim() || subtitle.trim()),
  });

  return (
    <div className={imageAdjustedClass} style={imageStyles}>
      <div className={classes('text', textAlignment)}>
        <div className={textWrapperClasses}>
          <h3 style={textStyles} className={classes('heading')}>{heading}</h3>
          <h1 style={textStyles} className={classes('title')}>{title}</h1>
          <h2 style={textStyles} className={classes('subtitle')}>{subtitle}</h2>
        </div>
        { cta && <SlideCTA className={classes('cta')} cta={cta} /> }
      </div>
    </div>
  );
};

SlideImage.propTypes = {
  sliderSize: PropTypes.number.isRequired,
  image: PropTypes.shape({
    alignment: PropTypes.oneOf(['top', 'center', 'bottom']).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    url_alternative: PropTypes.string,
  }).isRequired,
  heading: PropTypes.string,
  title: PropTypes.string,
  title_text_color: PropTypes.string,
  subtitle: PropTypes.string,
  text_alignment: PropTypes.string.isRequired,
  cta: PropTypes.string,
  variation: PropTypes.oneOf(['original', 'material']).isRequired,
};

SlideImage.defaultProps = {
  heading: '',
  title: '',
  title_text_color: '',
  subtitle: '',
  cta: '',
};

SlideImage.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = SlideImage;
