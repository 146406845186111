const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const TruckGlyph = require('./glyphs/truck');

const Shipping = ({ shipping, i18n }) => {
  let classNames = 'ui-item__shipping';
  if (shipping && shipping.freeShipping) {
    classNames += ' ui-item__shipping-free';
  }

  const shippingTitle = i18n.gettext('Envío gratis');

  return shipping ? (
    <div className={classNames} title={shippingTitle}>
      <TruckGlyph className="ui-item__shipping-icon" />
      <span className="ui-item__shipping-label">{shippingTitle}</span>
    </div>
  ) : null;
};

Shipping.propTypes = {
  i18n: PropTypes.shape({ gettext: PropTypes.func.isRequired }),
  shipping: PropTypes.shape({
    freeShipping: PropTypes.bool,
    text: PropTypes.string.isRequired,
  }),
};

Shipping.defaultProps = {
  i18n: { gettext: f => f },
  shipping: null,
};

module.exports = injectI18n(Shipping);
