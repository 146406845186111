const DebugInfo = require('../DebugInfo');
const UnknownComponent = require('./UnknownComponent');

/**
 * NORMAL LIST OF COMPONENTS
 */
const Document = require('../Document');
const Layout = require('../Layout');
const Row = require('../Row');

const ErrorScreen = require('../ErrorScreen');
const NotFoundScreen = require('../NotFoundScreen');

const HelpComponent = require('../HelpComponent');

const CarouselDesktop = require('../Carousel/desktop');
const CarouselMobile = require('../Carousel/mobile');
const CarouselWithTabsDesktop = require('../TabbedCarousel/desktop');
const CarouselWithTabsMobile = require('../TabbedCarousel/mobile');
const Discovery = require('../Discovery');
const LabelImage = require('../../components/labelImage');
const Mosaic = require('../Mosaic');
const PaymentMethodsDesktop = require('../PaymentMethods/desktop');
const PaymentMethodsMobile = require('../PaymentMethods/mobile');
const ShoppingInfoDesktop = require('../ShoppingInfo/desktop');
const ShoppingInfoMobile = require('../ShoppingInfo/mobile');
const Slider = require('../Slider');

const ContactForm = require('../ContactForm');
const ContactInfo = require('../ContactInfo');

const CollectionGridDesktop = require('../CollectionGrid/desktop');
const CollectionGridMobile = require('../CollectionGrid/mobile');

const PriceAndImage = require('../PriceAndImage');

const HowBuyInfo = require('../HowBuyInfo');

const DataPrivacyInfo = require('../DataPrivacyInfo');
const LegalNoticesInfo = require('../LegalNoticesInfo');
const AdhesionContractInfo = require('../AdhesionContractInfo');
const BuyerInfo = require('../BuyerInfo');

/**
 * EDITABLE LIST OF COMPONENTS
 * */
const DocumentEditable = require('../Document/editable');

const HeaderEditable = require('../Header/editable');
const BannerEditable = require('../Banner/editable');
const SliderEditable = require('../Slider/editable');
const WidgetsEditable = require('../WidgetsEditable/editable');

const CarouselDesktopEditable = require('../Carousel/desktop.editable');
const CarouselMobileEditable = require('../Carousel/mobile.editable');
const DiscoveryEditable = require('../Discovery/editable');
const MosaicEditable = require('../Mosaic/editable');

const CarouselWithTabsDesktopEditable = require('../TabbedCarousel/desktop.editable');
const CarouselWithTabsMobileEditable = require('../TabbedCarousel/mobile.editable');

const PaymentMethodsDesktopEditable = require('../PaymentMethods/desktop.editable');
const PaymentMethodsMobileEditable = require('../PaymentMethods/mobile.editable');

const ContactFormEditable = require('../ContactForm/editable');
const ContactInfoEditable = require('../ContactInfo/editable');

const CollectionGridDesktopEditable = require('../CollectionGrid/desktop.editable');
const CollectionGridMobileEditable = require('../CollectionGrid/mobile.editable');

const FooterEditable = require('../Footer/editable');
const PriceAndImageEditable = require('../PriceAndImage/editable');

/**
 * SPLINTER COMPONENTS
 */
const splinterComponents = require('../SplinterComponents/splinterComponents');

/**
 * EXPORTS
 */
module.exports = {
  defaultComponent: UnknownComponent,
  /**
   * All the available components
   */
  nonEditables: {
    Document,
    Layout,
    Row,
    ErrorScreen,
    NotFoundScreen,
    HelpComponent,
    ContactForm,
    ContactInfo,
    Slider: {
      displayAs: {
        original: Slider.Original,
        material: Slider.Material,
        default: Slider.Original,
      },
    },
    LabelImage,
    Discovery,
    Mosaic,
    HowBuyInfo,
    DataPrivacyInfo,
    LegalNoticesInfo,
    AdhesionContractInfo,
    PaymentMethods: {
      mobile: PaymentMethodsMobile,
      desktop: PaymentMethodsDesktop,
    },
    ShoppingInfo: {
      mobile: ShoppingInfoMobile,
      desktop: ShoppingInfoDesktop,
    },
    BuyerInfo,
    Collection: {
      displayAs: {
        carousel: { mobile: CarouselMobile, desktop: CarouselDesktop },
        original: { mobile: CarouselMobile, desktop: CarouselDesktop },
        material: { mobile: CarouselMobile, desktop: CarouselDesktop },
        default: { mobile: CarouselMobile, desktop: CarouselDesktop },
      },
    },
    TabbedCarousel: {
      mobile: CarouselWithTabsMobile,
      desktop: CarouselWithTabsDesktop,
    },
    CollectionGrid: {
      displayAs: {
        original: { mobile: CollectionGridMobile, desktop: CollectionGridDesktop },
        material: { mobile: CollectionGridMobile, desktop: CollectionGridDesktop },
        default: { mobile: CollectionGridMobile, desktop: CollectionGridDesktop },
      },
    },
    PriceAndImage,
    DebugInfo,
    ...splinterComponents.nonEditables,
  },
  /**
   * Components with an editable version
   */
  editables: {
    DocumentEditable,
    HeaderEditable,
    BannerEditable,
    SliderEditable: {
      displayAs: {
        original: SliderEditable.Original,
        material: SliderEditable.Material,
        default: SliderEditable.Original,
      },
    },
    CollectionEditable: {
      displayAs: {
        carousel: { mobile: CarouselMobileEditable, desktop: CarouselDesktopEditable },
        original: { mobile: CarouselMobileEditable, desktop: CarouselDesktopEditable },
        material: { mobile: CarouselMobileEditable, desktop: CarouselDesktopEditable },
        default: { mobile: CarouselMobileEditable, desktop: CarouselDesktopEditable },
      },
    },
    TabbedCarouselEditable: {
      mobile: CarouselWithTabsMobileEditable,
      desktop: CarouselWithTabsDesktopEditable,
    },
    CollectionGridEditable: {
      displayAs: {
        original: {
          mobile: CollectionGridMobileEditable,
          desktop: CollectionGridDesktopEditable,
        },
        material: {
          mobile: CollectionGridMobileEditable,
          desktop: CollectionGridDesktopEditable,
        },
        default: {
          mobile: CollectionGridMobileEditable,
          desktop: CollectionGridDesktopEditable,
        },
      },
    },
    DiscoveryEditable,
    MosaicEditable,
    PaymentMethodsEditable: {
      mobile: PaymentMethodsMobileEditable,
      desktop: PaymentMethodsDesktopEditable,
    },
    ContactFormEditable,
    ContactInfoEditable,
    WidgetsEditable,
    FooterEditable,
    PriceAndImageEditable,
    ...splinterComponents.editables,
  },
};
