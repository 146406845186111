const React = require('react');

const PropTypes = require('prop-types');
const classNames = require('classnames');
const Image = require('nordic/image');

const { TYPES } = require('../../utils/constants/components/message');

const { info, warning, error } = TYPES;

const Message = ({ text, icon, type, link }) => {
  const mainClass = classNames('message-container', {
    'message-container--info': (type === info),
    'message-container--warning': (type === warning),
    'message-container--error': (type === error),
  });

  return (
    <div className={mainClass}>
      <Image src={icon} width="24" alt="message-icon" />
      <span className="message-container__text">
        {text}
        {link && (
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            {link.text}
          </a>
        )}
      </span>
    </div>
  );
};

Message.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  type: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

Message.defaultProps = {
  type: info,
  link: null,
};

module.exports = Message;
